import ClassCard from "app/components/ClassCard";
import { ClassCardSkeleton } from "app/components/ClassCard/ClassCardSkeleton";
import { Slider } from "app/components/Slider";
import { Routes } from "constants/routes";
import React from "react";
import { GetHomeDataQuery } from "services/graphql";
import { HomeSelectContentContainer } from "services/typewriter/segment";
import { Section } from "../Section";

type TodaysClassesProps = {
  data: GetHomeDataQuery["todaysPlaylist"]["classes"] | [];
  loading?: boolean;
};
export const TodaysClasses = ({ data, loading }: TodaysClassesProps) => {
  const loadingStateComponent = () => {
    return (
      <Slider
        data={[{}, {}, {}, {}, {}]}
        slideComponent={ClassCardSkeleton}
        slidesSpacing={24}
        cardHeight="190px"
      />
    );
  };

  return (
    <Section
      title="Today's Classes"
      showLoadingState={loading}
      showTitleLoadingState
      loadingStateComponent={loadingStateComponent()}
      headerLink={{
        route: Routes.schedule,
        text: "Edit",
      }}
    >
      <Slider
        data={data}
        slideComponent={(props: any) => (
          <ClassCard
            {...props}
            container={HomeSelectContentContainer.TodaysClasses}
            selectedFrom="TodaysClasses"
          />
        )}
        slidesSpacing={24}
        cardHeight="190px"
      />
    </Section>
  );
};
